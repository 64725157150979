import { useEffect, useState } from "react";

import { App, Col, Modal, Row, theme, Typography } from "antd";
import {
  PlusOutlined,
  DownloadOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { DateTime } from "luxon";
import { useTranslation } from "react-i18next";

import Input from "../../components/form/Input.jsx";
import Select from "../../components/form/Select.jsx";
import PrimaryButton from "../../components/PrimaryButton.jsx";
import {
  useCreateOrganizationMutation,
  useGetCountriesQuery,
  useLazyExportOrganizationsQuery,
  useLazyGetOrganizationsQuery,
} from "../../redux/service.js";
import Table from "../../components/Table.jsx";
import FormField from "../../components/form/FormField.jsx";
import { handleErrors } from "../../utilities/index.js";
import {
  OrganizationCommunicationStatuses,
  OrganizationRoles,
  RegistrationStatus,
} from "../../utilities/constants.jsx";
import ImportOrganizationModal from "./components/ImportOrganizationModal.jsx";
import Tag from "../../components/Tag.jsx";

const { Title } = Typography;

const AddOrganizationModel = ({ open, onCancel }) => {
  const [createOrganization, { isLoading }] = useCreateOrganizationMutation();
  const { notification } = App.useApp();
  const { t } = useTranslation();
  const { data: countries, isSuccess: isCountriesSuccess } =
    useGetCountriesQuery();

  const createOrganizationSchema = yup.object().shape({
    name: yup
      .string()
      .typeError(t("form.validation.organizationNameNotValid"))
      .required(t("form.validation.organizationNameRequired")),
    country: yup
      .string()
      .typeError(t("form.validation.countryNotValid"))
      .required(t("form.validation.countryRequired")),
    address: yup
      .string()
      .typeError(t("form.validation.addressNotValid"))
      .required(t("form.validation.addressRequired")),
    phoneNumber: yup
      .string()
      .typeError(t("form.validation.phoneNumberNotValid")),
    email: yup
      .string()
      .email(t("form.validation.emailNotValid"))
      .typeError(t("form.validation.emailNotValid"))
      .required(t("form.validation.enterValidEmail")),
    externalId: yup
      .number()
      .integer(t("form.validation.externalIdNotValid"))
      .typeError(t("form.validation.externalIdNotValid"))
      .required(t("form.validation.externalIdRequired")),
    adminEmail: yup
      .string()
      .email(t("form.validation.emailNotValid"))
      .typeError(t("form.validation.emailNotValid"))
      .required(t("form.validation.enterValidEmail")),
  });

  const form = useForm({
    resolver: yupResolver(createOrganizationSchema),
    mode: "onChange",
  });

  const fields = [
    {
      label: t("form.labels.organizationName"),
      required: true,
      field: (
        <Input
          name="name"
          placeholder={t("form.placeholders.university")}
          control={form.control}
        />
      ),
    },
    {
      label: t("form.labels.country"),
      required: true,
      field: (
        <Select
          name="country"
          placeholder={t("form.placeholders.selectCountry")}
          options={isCountriesSuccess ? countries : []}
          control={form.control}
        />
      ),
    },
    {
      label: t("form.labels.organizationAddress"),
      required: true,
      field: (
        <Input
          name="address"
          placeholder={t("form.placeholders.address")}
          control={form.control}
        />
      ),
    },
    {
      label: t("form.labels.contactNumber"),
      field: (
        <Input
          name="phoneNumber"
          htmlType="phone"
          placeholder={t("form.placeholders.phoneNumber")}
          control={form.control}
        />
      ),
    },
    {
      label: t("form.labels.contactEmail"),
      required: true,
      field: (
        <Input
          name="email"
          placeholder={t("form.placeholders.email")}
          control={form.control}
        />
      ),
    },
    {
      label: t("form.labels.externalId"),
      required: true,
      field: (
        <Input
          name="externalId"
          htmlType="number"
          placeholder={t("form.placeholders.externalId")}
          control={form.control}
        />
      ),
    },
    {
      label: t("form.labels.adminEmail"),
      required: true,
      field: (
        <Input
          name="adminEmail"
          placeholder={t("form.placeholders.adminEmail")}
          control={form.control}
        />
      ),
    },
    {
      label: t("form.labels.members"),
      field: (
        <Select
          mode="tags"
          name="members"
          placeholder={t("form.placeholders.enterEmail")}
          open={false}
          control={form.control}
        />
      ),
    },
  ];

  const onClose = () => {
    form.reset();
    onCancel();
  };

  const onSubmit = (values) => {
    const { members, ...data } = values;
    createOrganization({
      ...data,
      invites: [
        { role: OrganizationRoles.ADMIN.value, email: values.adminEmail },
        ...(members || []).map((i) => ({
          email: i,
          role: OrganizationRoles.CONTRIBUTOR.value,
        })),
      ],
    })
      .unwrap()
      .then(() => {
        notification.success({
          message: t("messages.organizationCreated"),
        });
        onClose();
      })
      .catch((errors) => handleErrors(errors, form.setError, notification));
  };

  return (
    <Modal
      open={open}
      title={t("titles.createOrganization")}
      onCancel={onClose}
      footer={[
        <PrimaryButton key="cancel" onClick={onCancel}>
          {t("buttons.cancel")}
        </PrimaryButton>,
        <PrimaryButton
          key="confirm"
          disabled={isLoading}
          onClick={() => form.handleSubmit(onSubmit)()}
        >
          {t("buttons.confirm")}
        </PrimaryButton>,
      ]}
      modalRender={(element) => (
        <div className="modal-main-container">{element}</div>
      )}
    >
      {fields.map((field) => (
        <FormField key={field.label} {...field} />
      ))}
    </Modal>
  );
};

AddOrganizationModel.propTypes = {
  open: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
};

const ListOrganizations = () => {
  const { token } = theme.useToken();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { notification } = App.useApp();

  const [getPaginatedOrganizations, organizationsResponse] =
    useLazyGetOrganizationsQuery();
  const { data: countries, isSuccess: isCountriesSuccess } =
    useGetCountriesQuery();
  const [exportOrganizations] = useLazyExportOrganizationsQuery();

  const [openImportOrganizationsModel, setImportOrganizationModal] =
    useState(false);
  const [organizationStatusFilter, setOrganizationStatusFilter] =
    useState(null);
  const [countriesFilter, setCountriesFilter] = useState(null);
  const [openAddOrganizationModel, setOpenAddOrganizationModel] =
    useState(false);
  const [tableFilters, setTableFilters] = useState({});

  useEffect(() => {
    if (
      organizationsResponse.isSuccess &&
      isCountriesSuccess &&
      !countriesFilter
    ) {
      setCountriesFilter(
        Object.keys(organizationsResponse.data.countries).map((countryKey) => ({
          text: (
            <span>
              {countries.find((i) => i.value === countryKey)?.label}
              <span style={{ fontWeight: "bold" }}>
                {" "}
                ({organizationsResponse.data.countries[countryKey]})
              </span>
            </span>
          ),
          value: countryKey,
        })),
      );
    }
  }, [organizationsResponse, isCountriesSuccess]);

  useEffect(() => {
    if (organizationsResponse.isSuccess && !organizationStatusFilter) {
      setOrganizationStatusFilter(
        RegistrationStatus.asList().map((i) => ({
          text: (
            <span>
              {i.label}
              <span style={{ fontWeight: "bold" }}>
                {" "}
                ({organizationsResponse.data.registrationStatusCount[i.value]})
              </span>
            </span>
          ),
          value: i.value,
        })),
      );
    }
  }, [organizationsResponse]);

  const columns = [
    {
      title: t("form.labels.userId"),
      dataIndex: "externalId",
      key: "externalId",
      addSearch: true,
    },
    {
      title: t("form.labels.name"),
      dataIndex: "name",
      key: "name",
      addSearch: true,
      width: 400,
      sorter: true,
      render: (_, record) => (
        <div style={{ width: "350px" }}>
          <a
            onClick={() => navigate(`/organizations/${record.id}`)}
            style={{ color: token.linkColor, textDecoration: "underline" }}
          >
            {record.name}
          </a>
        </div>
      ),
    },
    {
      title: t("form.labels.status"),
      dataIndex: "registrationStatus",
      key: "registrationStatus",
      render: (_, record) => {
        const status = RegistrationStatus.getItemByValue(
          record.registrationStatus,
        );
        return (
          <Tag
            label={status.label}
            color={status.backgroundColor}
            style={{
              fontWeight: "bold",
              color: status.color,
            }}
            className="py-1"
          />
        );
      },
      filters: organizationStatusFilter || [],
      filterMultiple: false,
    },
    {
      title: t("form.labels.communicatingStatus"),
      dataIndex: "communicatingStatus",
      key: "communicatingStatus",
      render: (_, record) => {
        const communicatingStatus =
          OrganizationCommunicationStatuses.getItemByValue(
            record.communicatingStatus,
          );
        return (
          communicatingStatus && (
            <Tag
              label={communicatingStatus.label}
              color={communicatingStatus.backgroundColor}
              style={{ fontWeight: "bold", color: communicatingStatus.color }}
            />
          )
        );
      },
      filters: OrganizationCommunicationStatuses.asList().map((status) => ({
        value: status.value,
        text: status.label,
      })),
      filterMultiple: true,
    },
    {
      title: t("form.labels.country"),
      dataIndex: "countries",
      key: "countries",
      addSearch: true,
      render: (_, record) =>
        isCountriesSuccess &&
        countries.find((i) => i.value === record.country)?.label,
      filters: countriesFilter || [],
    },
    {
      title: t("form.labels.admin"),
      dataIndex: "admin.fullName",
      key: "adminFullName",
      render: (_, record) => (
        <a
          style={{ color: token.linkColor, textDecoration: "underline" }}
          onClick={() => navigate(`/profile/${record.admin.id}`)}
        >
          {record?.admin?.fullName}
        </a>
      ),
    },
    {
      title: t("form.labels.dateJoined"),
      dataIndex: "createdAt",
      key: "createdAt",
      render: (_, record) =>
        DateTime.fromISO(record.createdAt).toFormat("dd MMM yyyy"),
    },
  ];

  useEffect(() => {
    getPaginatedOrganizations({ page: 1, pageSize: 10, ordering: "name" });
  }, []);

  const handleTableChange = (pagination, filters, sorter) => {
    setTableFilters(filters);
    getPaginatedOrganizations({
      page: pagination.current,
      pageSize: pagination.pageSize,
      ...filters,
      ordering: sorter?.field
        ? `${sorter.order === "ascend" ? "-" : ""}${sorter.field[0]}`
        : "name",
    });
  };

  const handleOrganizationsExport = () => {
    exportOrganizations(tableFilters)
        .unwrap()
        .then(() =>
          notification.success({
            message: t("organizationsExportStarted")
          }),
        ).catch((response) => {
          if (response.data?.code === "not_found") {
            notification.error({ message: t("organizationsExportEmpty") });
            return
          }

          notification.success({
            message: t("organizationsExportGenericError")
          })
    })
  }

  return (
    <div>
      <Row
        className="mb-4"
        style={{ display: "flex", justifyContent: "space-between" }}
      >
        <Col>
          <Title level={3} style={{ color: token.colorTextThird }}>
            {t("organizationsList")}
          </Title>
        </Col>
        <Col>
          <Row>
            {organizationsResponse.isSuccess && (
              <Col
                className="me-3"
                style={{ display: "flex", alignItems: "center" }}
              >
                <Row>
                  <Col>
                    <Title level={5}>
                      {t("registered")}:{" "}
                      {
                        organizationsResponse.data.registrationStatusCount[
                          RegistrationStatus.REGISTERED.value
                        ]
                      }
                    </Title>
                  </Col>
                  <Col className="ms-2">
                    <Title level={5}>
                      {t("pending")}:{" "}
                      {
                        organizationsResponse.data.registrationStatusCount[
                          RegistrationStatus.PENDING.value
                        ]
                      }
                    </Title>
                  </Col>
                </Row>
              </Col>
            )}
            <Col>
              <PrimaryButton onClick={() => setOpenAddOrganizationModel(true)}>
                <PlusOutlined style={{ verticalAlign: "initial" }} />
                {t("buttons.invite")}
              </PrimaryButton>
            </Col>
            <Col>
              <PrimaryButton
                className="ms-3"
                onClick={() => setImportOrganizationModal(true)}
              >
                <UploadOutlined style={{ verticalAlign: "initial" }} />
                {t("buttons.import")}
              </PrimaryButton>
            </Col>
            <Col>
              <PrimaryButton
                className="ms-3"
                onClick={handleOrganizationsExport}
              >
                <DownloadOutlined style={{ verticalAlign: "initial" }} />
                {t("buttons.export")}
              </PrimaryButton>
            </Col>
          </Row>
        </Col>
      </Row>
      <Table
        data={organizationsResponse.data?.results || []}
        totalCount={organizationsResponse.data?.count || 0}
        columns={columns}
        isLoading={
          organizationsResponse.isUninitialized ||
          organizationsResponse.isFetching
        }
        handleTableChange={handleTableChange}
        initialPageSize={10}
        scroll={{ x: 500 }}
      />
      <AddOrganizationModel
        open={openAddOrganizationModel}
        onCancel={() => setOpenAddOrganizationModel(false)}
      />
      <ImportOrganizationModal
        open={openImportOrganizationsModel}
        onCancel={() => setImportOrganizationModal(false)}
      />
    </div>
  );
};

export default ListOrganizations;
