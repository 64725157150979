import { createApi } from "@reduxjs/toolkit/query/react";
import * as Sentry from "@sentry/react";

import usersService from "./users/services.js";
import { clientApi } from "../utilities/api.js";
import notificationsService from "./notifications/services.js";
import organizationService from "./organization/services.js";
import objectsService from "./objects/services.js";
import narrativesService from "./narratives/services.js";
import surveyService from "./survey/services.js";
import utilsService from "./utils/services.js";
import communityService from "./community/services.js";

const axiosBaseQuery =
  ({ baseUrl }) =>
  async (props) => {
    try {
      const url = props?.url || props;
      const result = await clientApi({
        url: baseUrl + url,
        method: props?.method || "GET",
        data: props?.data,
        params: props?.params,
        headers: props?.headers,
      });
      return { data: result.data };
    } catch (error) {
      Sentry.captureException(error, {
        extra: {
          url: props?.url,
          method: props?.method || "GET",
          params: props?.params,
        },
      });
      return {
        error: {
          status: error.response?.status,
          data: error.response?.data || error.message,
        },
      };
    }
  };

export const commonsApi = createApi({
  reducerPath: "commonsApi",
  baseQuery: axiosBaseQuery({
    baseUrl: import.meta.env.VITE_APP_BACKEND_URL,
  }),
  endpoints: (builder) => ({
    ...usersService(builder),
    ...notificationsService(builder),
    ...organizationService(builder),
    ...objectsService(builder),
    ...narrativesService(builder),
    ...surveyService(builder),
    ...utilsService(builder),
    ...communityService(builder),
  }),
});

export const {
  useGetCountriesQuery,
  useGetLanguagesQuery,
  useLoginMutation,
  useRegisterMutation,
  useResetPasswordMutation,
  useResetPasswordConfirmMutation,
  useVerifyEmailMutation,
  useVerifyEmailResendMutation,
  useGetCurrentUserQuery,
  useLazyGetCurrentUserQuery,
  useLazyGetNotificationsByReadStatusQuery,
  useGetUnreadNotificationsCountQuery,
  useVerifyInviteEmailQuery,
  useReadNotificationsMutation,
  useLogOutMutation,
  useUpdateCurrentUserMutation,
  useDeleteCurrentUserMutation,
  useDeleteOrganizationByIdMutation,
  useLazyGetOrganizationByIdQuery,
  usePatchOrganizationByIdMutation,
  useDeleteMembershipByIdMutation,
  useInviteUserMutation,
  useDeleteInviteByIdMutation,
  useLazyGetOrganizationsQuery,
  useCreateOrganizationMutation,
  usePatchInviteByIdMutation,
  usePatchMembershipByIdMutation,
  useLazyPaginateFilterObjectsQuery,
  usePaginateFilterObjectsQuery,
  useCreateObjectMutation,
  useLazyGetObjectByIdQuery,
  useGetObjectByIdQuery,
  useEditObjectMutation,
  useDeleteObjectMutation,
  useLazyGetMembershipsQuery,
  useGetMembershipsQuery,
  useCreateNarrativeMutation,
  useUpdateNarrativeMutation,
  useGetNarrativeByIdQuery,
  usePublishNarrativeMutation,
  useDeleteNarrativeMutation,
  useLazyPaginateFilterNarrativesQuery,
  useLazyGetNarrativeByIdQuery,
  useLazyGetSurveyStatisticsQuery,
  useGetPrincipleByIdQuery,
  useGetSurveyStatisticsQuery,
  useGetQuestionByIdQuery,
  useCreateAnswerMutation,
  useDeleteAnswerMutation,
  useUpdateAnswerMutation,
  usePatchAnswerMutation,
  useGetSurveyByIdQuery,
  useLazyGetPrinciplesQuery,
  useUpdateSurveyDetailsMutation,
  useImportOrganizationsFromCsvMutation,
  useLazyPaginateFilterSurveysQuery,
  usePaginateFilterSurveysQuery,
  useLazyGetAdminSurveyStatisticsQuery,
  useSendSurveyInvitesMutation,
  useExportOrganizationDataMutation,
  useGetExportedOrganizationDataQuery,
  useGetExportedUserDataQuery,
  useExportUserDataMutation,
  useCreateSurveyMutation,
  usePatchSurveyMutation,
  useCreatePrincipleMutation,
  useGetPrinciplesQuery,
  useUpdatePrincipleMutation,
  useDeletePrincipleMutation,
  useDeleteSurveyMutation,
  useGetQuestionsQuery,
  useCreateQuestionMutation,
  useUpdateQuestionMutation,
  useDeleteQuestionMutation,
  usePatchOptionMutation,
  useGetUserByIdQuery,
  useLazyGetUserByIdQuery,
  useUpdateUserMutation,
  useLazyExportOrganizationsQuery,
  useLazyGetAnswerByIdQuery,
  useLazyImpersonateUserQuery,
  useLazyPaginateFilterDiscussionsQuery,
  useGetDiscussionsStatisticsQuery,
  useLazyGetUsersQuery,
  useCreateDiscussionMutation,
  useGetSubjectsQuery,
  useLazyGetSubjectsQuery,
  useGetTopicsQuery,
  useLazyGetTopicsQuery,
  useGetDiscussionByIdQuery,
  useLikeDiscussionMutation,
  useLazyGetDiscussionByIdQuery,
  useUpdateDiscussionMutation,
  useDeleteDiscussionMutation,
  usePatchDiscussionMutation,
  useLazyReportDiscussionQuery,
  useCreateTopicMutation,
  useUpdateTopicMutation,
  useDeleteTopicMutation,
  useFollowUserMutation,
  useUnfollowUserMutation,
  useLazyGetCommentsQuery,
  useGetCommentsQuery,
  useDeleteCommentMutation,
  useCreateCommentMutation,
  useLikeCommentMutation,
  useUpdateCommentMutation,
  useLazyReportCommentQuery,
  usePatchCommentMutation,
  useLazyIncrementDiscussionViewsQuery,
  useLazyIncrementObjectViewsQuery,
  useGetFrequentlyAskedQuestionsQuery,
  useLazyPaginateFilterSurveyViewsQuery,
  usePaginateFilterSurveyViewsQuery,
  useDeleteSurveyViewMutation,
  useLazyGetQuestionsQuery,
  useLazyGetAnswersQuery,
  useCreateSurveyViewMutation,
  useLazyGetSurveyViewByIdQuery,
  useUpdateSurveyViewMutation,
  useLazyGetSurveyByIdQuery,
  useLazyGetSurveyInvitesQuery,
  useLazyIncrementNarrativeViewsQuery,
  useGetInvitesQuery,
  useLazyGetSurveyAdminStatisticsQuery,
  useGetUsersQuery,
  useGetNotificationSettingsForUserQuery,
  usePatchNotificationSettingsMutation,
  useGetEmailSettingsForUserQuery,
  usePatchEmailSettingsMutation,
  useGetCommentQuery,
  useLazyIncrementSurveyViewViewsQuery,
  useLazyRequestObjectsExportQuery,
  useGetTopicNotificationSettingsForUserQuery,
  useCreateTopicNotificationSettingsMutation,
  usePatchTopicNotificationSettingsMutation,
} = commonsApi;
