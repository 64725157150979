import { useEffect, useState } from "react";

import { useTranslation } from "react-i18next";
import { Col, Row, Typography } from "antd";
import {useDispatch, useSelector} from "react-redux";

import {
  useGetCurrentUserQuery,
  useLazyGetSurveyInvitesQuery,
} from "../../redux/service.js";
import { Roles } from "../../utilities/constants.jsx";
import SurveyTimeline from "./components/SurveyTimeline.jsx";
import OverdueSurveysReminder from "./components/OverdueSurveysReminder.jsx";
import {setIsFirstLogin} from "../../redux/users/reducer.js";

const { Title, Text } = Typography;

const Home = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const isFirstLogin = useSelector((state) => state.auth.isFirstLogin);
  const [getOverdueSurveyInvites, overdueSurveyInvitesResponse] =
    useLazyGetSurveyInvitesQuery({ pagination: "off", overdue: "true" });
  const { data: currentUser, isSuccess: isCurrentUserSuccess } =
    useGetCurrentUserQuery();
  const [isReminderVisible, setIsReminderVisible] = useState(true);

  useEffect(() => {
    if (isCurrentUserSuccess && currentUser.role !== Roles.ADMIN.value) {
      getOverdueSurveyInvites({ pagination: "off", overdue: "true" });
    }
  }, [isCurrentUserSuccess]);

  useEffect(() => {
    if (isFirstLogin) {
        window.Storylane.Play({
            type: 'popup',
            demo_type: 'image',
            width: 2560,
            height: 1299.2,
            scale: '0.95',
            demo_url: 'https://prme.storylane.io/demo/tuxjeaqartam?embed=popup',
            padding_bottom: 'calc(50.75% + 25px)'
          })
      dispatch(setIsFirstLogin(false));
    }
  }, [isFirstLogin]);

  return (
    isCurrentUserSuccess && (
      <Row style={{ height: "100%" }}>
        <Col lg={18} md={24} sm={24} className="mb-3 pt-3 ps-2">
          <div
            className={`px-5 mt-${overdueSurveyInvitesResponse.isSuccess ? "2" : "5"}`}
          >
            {overdueSurveyInvitesResponse.isSuccess &&
            overdueSurveyInvitesResponse.data.length > 0 && isReminderVisible ? (
              <OverdueSurveysReminder
                setIsVisible={setIsReminderVisible}
                surveys={overdueSurveyInvitesResponse.data.map((i) => i.survey)}
              />
            ) : null}
            <Row>
              <Col lg={13} md={24} sm={24} className="onboarding-content">
                <Title
                  level={1}
                  style={{ fontWeight: "bold", color: "rgba(30, 50, 80, 1)" }}
                >
                  {t("titles.welcomeToCommons")}
                </Title>
                <div className="mt-4">
                  <Text
                    style={{ fontSize: "15px", color: "rgba(30, 50, 80, 1)" }}
                  >
                    {t("onboardingPagePart1")}
                    <strong>{t("sharingInformationProgress")}</strong>
                    {t("onboardingPagePart2")}
                    <a
                      href="https://www.unprme.org/search-sips/"
                      target="_blank"
                      rel="noreferrer noopener"
                      style={{
                        textDecoration: "underline",
                        color: "rgba(30, 50, 80, 1)",
                      }}
                    >
                      {t("prmeWebsite")}
                    </a>
                    {t("onboardingPagePart3")}
                  </Text>
                  <div className="mt-3">
                    <Text
                      style={{ fontSize: "15px", color: "rgba(30, 50, 80, 1)" }}
                    >
                      <p dangerouslySetInnerHTML={{ __html: t("onboardingPagePart4") }} />
                    </Text>
                  </div>
                </div>
              </Col>
              <Col lg={11} md={24} sm={24} className="onboarding-image">
                <img
                  style={{ width: "90%" }}
                  src="/assets/prme-commons-principles.png"
                  alt="prme commons principles"
                />
              </Col>
            </Row>
          </div>
        </Col>
        <Col
          lg={6}
          md={24}
          sm={24}
          style={{ backgroundColor: "rgba(243, 243, 247, 1)" }}
        >
          <div
            className="py-4 px-3"
            style={{ height: "83vh", overflowY: "auto" }}
          >
            <SurveyTimeline />
          </div>
        </Col>
      </Row>
    )
  );
};

export default Home;
