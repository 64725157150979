/* eslint-disable react-refresh/only-export-components */
export const LocalStorageName = `prme-commons-${import.meta.env.VITE_ENV}`;

// this is used to show new surveys for which invites have not yet been sent
export const NEW_SURVEY = {
    id: null,
    title: "SIP 2025",
    startDate: "2025-03-01T00:00:00-05:00",
    hardDeadline: "2025-06-30T00:00:00-04:00"
}

export const EnumFunctions = {
  getProperties() {
    const properties = Object.getOwnPropertyNames(this).filter(
      (i) => typeof this[i] !== "function",
    );
    return properties.reduce((acc, i) => {
      acc[this[i]?.value || this[i].label] = this[i].label;
      return acc;
    }, {});
  },
  asList() {
    const obj = this.getProperties();
    return Object.keys(obj).map((i) => ({ label: obj[i], value: i }));
  },
  getValues() {
    return Object.keys(this.getProperties()).map((i) => i);
  },
  getLabel(val) {
    return this.getProperties()[val];
  },
  getItemByValue(val) {
    const properties = Object.values(this).filter(
      (i) => typeof i !== "function",
    );
    return properties.find((i) => i.value === val);
  },
};

export const Roles = {
  ADMIN: {
    value: "ADMIN",
    label: "PRME Agent",
    color: "#1E3250",
  },
  USER: {
    value: "USER",
    label: "User",
    color: "#1E3250",
  },
  ...EnumFunctions,
};

export const OrganizationRoles = {
  ADMIN: {
    value: "ADMIN",
    label: "Admin",
    color: "#1E3250",
  },
  SUPERVISOR: {
    value: "SUPERVISOR",
    label: "Supervisor",
    color: "#4e6483",
  },
  CONTRIBUTOR: {
    value: "CONTRIBUTOR",
    label: "Contributor",
    color: "#afc1d9",
  },
  MEMBER: {
    value: "MEMBER",
    label: "Member",
    color: "#afc1d9",
  },
  ...EnumFunctions,
};

export const SurveyStatus = {
  DRAFT: {
    value: "DRAFT",
    label: "Draft",
    background: "rgba(255, 191, 1, 0.185)",
    color: "rgba(120, 50, 0, 0.812)",
  },
  ACTIVE: {
    value: "ACTIVE",
    label: "Active",
    background: "rgba(2, 186, 60, 0.087)",
    color: "rgba(0, 107, 59, 0.906)",
  },
  ENDED: {
    value: "ENDED",
    label: "Ended",
    background: "rgba(255, 1, 1, 0.063)",
    color: "rgba(187, 0, 7, 0.836)",
  },
  ...EnumFunctions,
};

export const SurveyType = {
  SIP: {
    value: "SIP",
    label: "SIP",
  },
  I5: {
    value: "I5",
    label: "i5",
  },
  ...EnumFunctions,
};

export const SurveyActions = {
  SUBMIT: {
    value: "SUBMIT",
    label: "Submit",
  },
  EDIT: {
    value: "EDIT",
    label: "Edit",
  },
  ...EnumFunctions,
};

export const SurveyAudience = {
  ORGANIZATION: {
    value: "ORGANIZATION",
    label: "Organization",
  },
  USER: {
    value: "USER",
    label: "User",
  },
};

export const MenuItems = (currentUser) => [
  {
    icon: <img src="/assets/home.svg" alt="home icon" className="sidebar-icon" />,
    key: "/",
    label: "Home",
  },
  {
    icon: <img src="/assets/dashboard.svg" className="sidebar-icon" />,
    key: "/dashboard",
    label: "Dashboard",
    roles: [Roles.ADMIN.value],
  },
  {
    icon: (
      <img src="/assets/object-repository.svg" alt="object repository icon" className="sidebar-icon" />
    ),
    key: "/objects",
    label: "Objects",
  },
  {
    icon: <img src="/assets/narratives.svg" alt="narratives icon" className="sidebar-icon" />,
    key: "/narratives",
    label: "Narratives",
  },
  {
    icon: <img src="/assets/organization-icon.svg" alt="organization icon" className="sidebar-icon"/>,
    key:
      currentUser.role === Roles.ADMIN.value
        ? "/organizations"
        : `/organizations/${currentUser.organization.id}`,
    label:
      currentUser.role === Roles.ADMIN.value ? "Organizations" : "Organization",
  },
  {
    icon: <img src="/assets/survey-icon.svg" alt="sip survey icon" className="sidebar-icon" />,
    key: "/reports",
    label: "Reports",
  },
  {
    icon: <img src="/assets/community.svg" alt="community icon" className="sidebar-icon" />,
    key: "/community",
    label: "Community",
  },
];

export const ObjectTypes = {
  STATEMENT: {
    value: "STATEMENT",
    label: "Statement",
  },
  POLICY: {
    value: "POLICY",
    label: "Policy",
  },
  LEARNING_OBJECT: {
    value: "LEARNING_OBJECT",
    label: "Learning Object",
  },
  EVENT: {
    value: "EVENT",
    label: "Event",
  },
  PUBLIC_MEDIA: {
    value: "PUBLIC_MEDIA",
    label: "Public Media",
  },
  ...EnumFunctions,
};

export const OptionType = {
  SINGLE_CHOICE: {
    label: "Single choice",
    value: "SINGLE_CHOICE",
  },
  MULTIPLE_CHOICE: {
    label: "Multiple choice",
    value: "MULTIPLE_CHOICE",
  },
  CHOICE_TEXT: {
    label: "Text choice",
    value: "CHOICE_TEXT",
  },
  TEXT: {
    label: "Text",
    value: "TEXT",
  },
  OBJECT: {
    label: "Object",
    value: "OBJECT",
  },
  NARRATIVE: {
    label: "Narrative",
    value: "NARRATIVE",
  },
  ...EnumFunctions,
};

export const OptionsOrientation = {
  VERTICAL: {
    label: "Vertical",
    value: "VERTICAL",
  },
  HORIZONTAL: {
    label: "Horizontal",
    value: "HORIZONTAL",
  },
  ...EnumFunctions,
};

export const SharingRights = {
  PRIVATE: {
    value: "PRIVATE",
    label: "Private",
  },
  PRME_COMMUNITY: {
    value: "PRME_COMMUNITY",
    label: "PRME Community",
  },
  PUBLIC: {
    value: "PUBLIC",
    label: "Public",
  },
  ...EnumFunctions,
};

export const PrincipleStatus = {
  NOT_STARTED: {
    value: "NOT_STARTED",
    label: "Start",
  },
  IN_PROGRESS: {
    value: "IN_PROGRESS",
    label: "Continue",
  },
  FINISHED: {
    value: "FINISHED",
    label: "View",
  },
  ...EnumFunctions,
};

export const RegistrationStatus = {
  PENDING: {
    value: "PENDING",
    label: "Pending",
    backgroundColor: "rgba(1, 68, 255, 0.059)",
    color: "rgba(0, 37, 158, 0.797)",
  },
  REGISTERED: {
    value: "REGISTERED",
    label: "Registered",
    backgroundColor: "rgba(2, 186, 60, 0.087)",
    color: "rgba(0, 107, 59, 0.906)",
  },
  ...EnumFunctions,
};

export const Chapters = {
  AFRICA: {
    value: "AFRICA",
    label: "Africa",
  },
  ASEAN: {
    value: "ASEAN",
    label: "ASEAN+",
  },
  AUSTRALIA_NEW_ZEALAND: {
    value: "AUSTRALIA_NEW_ZEALAND",
    label: "Australia & New Zealand",
  },
  BRAZIL: {
    value: "BRAZIL",
    label: "Brazil",
  },
  CEE: {
    value: "CEE",
    label: "CEE",
  },
  DACH: {
    value: "DACH",
    label: "DACH",
  },
  EURASIA: {
    value: "EURASIA",
    label: "Eurasia",
  },
  FRANCE_BENELUX: {
    value: "FRANCE_BENELUX",
    label: "France & Benelux",
  },
  IBERIA: {
    value: "IBERIA",
    label: "Iberia",
  },
  INDIA: {
    value: "INDIA",
    label: "India",
  },
  ITALY: {
    value: "ITALY",
    label: "Italy",
  },
  LAC: {
    value: "LAC",
    label: "LAC",
  },
  MIDDLE_EAST: {
    value: "MIDDLE_EAST",
    label: "Middle East",
  },
  NORDIC: {
    value: "NORDIC",
    label: "Nordic",
  },
  NORTH_AMERICA: {
    value: "NORTH_AMERICA",
    label: "North America",
  },
  POLAND: {
    value: "POLAND",
    label: "Poland",
  },
  UK_IRELAND: {
    value: "UK_IRELAND",
    label: "UK & Ireland",
  },
  ...EnumFunctions,
};

export const OrganizationCommunicationStatuses = {
  COMMUNICATING: {
    value: "COMMUNICATING",
    label: "Communicating",
    backgroundColor: "rgba(162, 195, 245, 1)",
    color: "rgba(63, 95, 146, 1)",
  },
  NON_COMMUNICATING: {
    value: "NON_COMMUNICATING",
    label: "Non-Communicating",
    backgroundColor: "rgba(255, 1, 1, 0.06)",
    color: "rgba(187, 0, 7, 0.84)",
  },
  PENDING_DELISTING: {
    value: "PENDING_DELISTING",
    label: "Pending Delisting",
    backgroundColor: "rgba(255, 191, 1, 0.19)",
    color: "rgba(120, 50, 0, 0.81)",
  },
  DELISTED: {
    value: "DELISTED",
    label: "Delisted",
    backgroundColor: "rgba(255, 191, 1, 0.19)",
    color: "rgba(120, 50, 0, 0.81)",
  },
  ...EnumFunctions,
};

export const SipPreviews = {
  languages: [
    { label: "English", value: "en" },
    { label: "Spanish", value: "es" },
    { label: "French", value: "fr" },
    { label: "Chinese", value: "cn" },
  ],
  getLink: (language) =>
    `https://commons-static.s3.eu-central-1.amazonaws.com/sip-previews/SIP_2.0_Preview_${language.toUpperCase()}.pdf`,
};


export const NotificationTypes = {
  MENTION: {
    value: "MENTION",
  },
  POST_COMMENT_CREATE: {
    value: "POST_COMMENT_CREATE",
  },
  COMMENT_REPLY_CREATE: {
    value: "COMMENT_REPLY_CREATE",
  },
  POST_LIKE: {
    value: "POST_LIKE",
  },
  FEATURED_CONTENT: {
    value: "FEATURED_CONTENT",
  },
  ...EnumFunctions,
}

export const EmailTypes = {
  DIGEST: {
    value: "DIGEST"
  },
  ...EnumFunctions
}


export const Frequency = {
  DAILY: {
    value: "DAILY",
    label: "Daily",
  },
  WEEKLY: {
    value: "WEEKLY",
    label: "Weekly",
  },
  BIWEEKLY: {
    value: "BIWEEKLY",
    label: "Bi-weekly",
  },
  MONTHLY: {
    value: "MONTHLY",
    label: "Monthly"
  },
  ...EnumFunctions
};
